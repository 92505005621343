import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <section id="products">
        <h1 className="section-title">Products</h1>
        <div className="product-item">
          <h2>Product 1</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <button className="demo-button">Demo</button>
        </div>
        <div className="product-item">
          <h2>Product 2</h2>
          <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          <button className="demo-button">Demo</button>
        </div>
        <div className="product-item">
          <h2>Product 3</h2>
          <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          <button className="demo-button">Demo</button>
        </div>
        <div className="product-item">
          <h2>Product 4</h2>
          <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
          <button className="demo-button">Demo</button>
        </div>
      </section>
      <section id="contact">
        <h1 className="section-title">Contact Us</h1>
        <ul className="contact-list">
          <li><i className="fas fa-envelope"></i> info@mycompany.com</li>
          <li><i className="fas fa-phone"></i> (123) 456-7890</li>
          <li><i className="fas fa-map-marker-alt"></i> 1234 Main St, Anytown USA</li>
        </ul>
      </section>
    </div>
  );
}

export default App;
